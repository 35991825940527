import { useState } from 'react'

import { Menu, Checkbox, FloatingPosition } from '@mantine/core'

export interface CheckboxMenuItem {
  label: string
  value: string
}

export interface CheckboxMenuProps {
  items: CheckboxMenuItem[]
  selectedItems: CheckboxMenuItem[]
  setSelectedItems: (items: CheckboxMenuItem[]) => void
  children: React.ReactNode
  fallbackText: string
  onClose?: () => void
  position?: FloatingPosition
}

const CheckboxMenu = ({
  items,
  selectedItems,
  setSelectedItems,
  children,
  fallbackText,
  onClose = () => {},
  position = 'bottom-end' as FloatingPosition,
}) => {
  const [checkboxMenuOpen, setCheckboxMenuOpen] = useState(false)

  /**********************    FUNCTIONS    **********************/
  const handleCheckbox = (checked, item) => {
    const isItemSelected = selectedItems.some((r) => r.label === item.label)

    if (checked && !isItemSelected) {
      addItem(item)
    } else if (!checked && isItemSelected) {
      removeItem(item)
    }
  }

  const addItem = (item) => {
    const updatedItems = [...selectedItems, item]
    setSelectedItems(updatedItems)
  }

  const removeItem = (item) => {
    let updatedItems = [...selectedItems]
    updatedItems = updatedItems.filter((r) => r.label !== item.label)
    setSelectedItems(updatedItems)
  }

  return (
    <>
      <Menu
        shadow="md"
        position={position}
        closeOnItemClick={false}
        opened={checkboxMenuOpen}
        onChange={setCheckboxMenuOpen}
        onClose={onClose}
      >
        <Menu.Target>
          <div>{children}</div>
        </Menu.Target>
        <Menu.Dropdown className="w-[14.5rem]">
          {items.length > 0 ? (
            <>
              {items.map((item, index) => {
                return (
                  <Menu.Item key={index}>
                    <Checkbox
                      label={item.label}
                      checked={
                        selectedItems.find((r) => r.label === item.label) !==
                        undefined
                      }
                      onChange={(event) =>
                        handleCheckbox(event.currentTarget.checked, item)
                      }
                    />
                  </Menu.Item>
                )
              })}
            </>
          ) : (
            <div className="px-2 py-4 text-sm font-medium">{fallbackText}</div>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  )
}

export default CheckboxMenu
